import { React } from "react";
import image2 from "../../assets/img/2023_logo.png";

function LiveEvent() {
  return (
    <section className="">
      <div className="container">
         <div className="live_event">
            <img src={image2} alt="img"/>
             <h1>Join us on 3rd Feb 2024 at 5:00 p.m</h1>
            <p>to witness the live premiere of</p>
            <h2>Channelier FMCG Awards 2023</h2>
         </div>
      </div>
    </section>
  );
}

export default LiveEvent;

// Imports the requirements
import React, { useEffect } from "react";
import logo from "../../assets/img/logo.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";

// Header Function
function Header() {
  const location = useLocation();
  const url = location.pathname;

  // // Sticky Menu Area
  // useEffect(() => {
  //   window.addEventListener("scroll", isSticky);
  //   return () => {
  //     window.removeEventListener("scroll", isSticky);
  //   };
  // });

  // /* Method that will fix header after a specific scrollable */
  // const isSticky = (e) => {
  //   const header = document.querySelector(".header");
  //   const scrollTop = window.scrollY;
  //   scrollTop >= 10
  //     ? header.classList.add("is-sticky")
  //     : header.classList.remove("is-sticky");
  //   // console.log(header);
  // };

  // Header UI
  return (
    <Navbar expand="lg" className="header is-sticky">
      <Navbar.Brand href="https://awards.channelier.com">
        {" "}
        <img src={logo} alt="Logo" className="logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          className={`navlinks ${
            url.includes("/home") ? "text-white" : "text-black"
          }`}
        >
          <Nav.Link href="/">Home</Nav.Link>
          {/* drop down */}
          <NavDropdown
            title="Winners"
            id="navbar_dropdown_winners"
            // alignRight
            className="cstom-dropdown"
          >
            <NavDropdown.Item href="winner-category">2022</NavDropdown.Item>
            <NavDropdown.Item href="winners">2021</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/award-category">Categories</Nav.Link>
          <Nav.Link href="/how-to-participate">How to participate?</Nav.Link>
          <Nav.Link href="/jury">Jury</Nav.Link>
          <Nav.Link href="/why-participate">Why participate</Nav.Link>
          <Nav.Link href="/gallery">Gallery</Nav.Link>
          <Nav.Link href="/faq">FAQs</Nav.Link>
          <Nav.Link href="/login">Login</Nav.Link>

          {/* <Nav.Link href="/winners">2021</Nav.Link>
          <Nav.Link href="/#2022">2022</Nav.Link> */}
          {/* <Nav.Link href="/#about">About</Nav.Link> */}
          {/* <Nav.Link href="/#benefit">Benefits</Nav.Link> */}
          {/* <Nav.Link href = "/voting-category">Voting</Nav.Link>   */}
          {/* <Nav.Link href="/contact-us">Contact</Nav.Link> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
